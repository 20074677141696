
import { Component, Prop, Watch } from "vue-property-decorator";
import { BaseMixin } from "@/mixins/baseMixin";
import Table from "@/components/better_table/components/Table.vue";
import "vue-good-table/dist/vue-good-table.css";
import AppConfig from "@/config/AppConfig";
import router from "@/router";
import i18n from "@/locales";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import UpdateButton from "@/components/buttons/UpdateButton.vue";
import { BModal } from "bootstrap-vue";
import AddButton from "@/components/buttons/AddButton.vue";
import CatalogExhibitorListButton from "@/components/buttons/CatalogExhibitorListButton.vue";
import SendMailButton from "@/components/buttons/SendMailButton.vue";
import SendMailMailClientButton from "@/components/buttons/SendMailMailClientButton.vue";
import PasswordResetButton from "@/components/buttons/PasswordResetButton.vue";
import ApproveButton from "@/components/buttons/ApproveButton.vue";
import RejectButton from "@/components/buttons/RejectButton.vue";
import PagePositionField from "@/components/better_table/components/PagePositionField.vue";
import ApproveLogoButton from "@/components/buttons/ApproveLogoButton.vue";
import ResetFiltersButton from "@/components/buttons/ResetFiltersButton.vue";
import ReloadButton from "@/components/buttons/ReloadButton.vue";
import AuthImage from "@/directives/AuthImage";
import DropDownButton from "@/components/DropDownButton.vue";
import CatalogPagesButton from "@/components/buttons/CatalogPagesButton.vue";
import VgtNewPagination from "@/components/better_table/components/VgtNewPagination.vue";
import PagesButton from "@/components/buttons/PagesButton.vue";
import WinesButton from "@/components/buttons/WinesButton.vue";
import ReminderButton from "@/components/buttons/ReminderButton.vue";
import LoginAsExhibitorButton from "@/components/buttons/LoginAsExhibitorButton.vue";

@Component({
  components: {
    ReminderButton,
    PagePositionField,
    ApproveButton,
    ApproveLogoButton,
    AddButton,
    ResetFiltersButton,
    ReloadButton,
    Table,
    BModal,
    UpdateButton,
    DeleteButton,
    CatalogExhibitorListButton,
    SendMailButton,
    SendMailMailClientButton,
    PasswordResetButton,
    RejectButton,
    DropDownButton,
    CatalogPagesButton,
    VgtNewPagination,
    WinesButton,
    PagesButton,
    LoginAsExhibitorButton
  },
  directives: {
    AuthImage: new AuthImage()
  }
})
export default class MisTable extends BaseMixin {
  @Prop({ default: true })
  public showResetFiltersButton!: boolean;

  @Prop({ default: true })
  public showReloadButton!: boolean;

  @Prop({ default: "" })
  public pageUpdateUrl?: string;

  @Prop({ default: "" })
  public addRouteName?: string;

  @Prop({ default: "" })
  public deleteResource?: string;

  @Prop({ default: "" })
  public resourceName: any;

  @Prop({ default: [] })
  public dataList: any;

  @Prop({ default: 0 })
  public totalRows: any;

  @Prop({ default: {} })
  public error: any;

  @Prop({ default: true })
  public updateBtn: any;

  @Prop({ default: false })
  public resetBtn: any;

  @Prop({ default: false })
  public isLoading: any;

  @Prop({ default: false })
  public deleted: any;

  @Prop({ default: () => {} })
  public searchAction: any;

  @Prop({ default: () => {} })
  public deleteAction: any;

  @Prop({ default: [] })
  public columns: any;

  @Prop({ default: [] })
  public searchFields: any;

  @Prop({ default: true })
  public showCreateButton!: boolean;

  @Prop({ default: null })
  public createButtonUrl?: any;

  @Prop({ default: true })
  public showDeleteButton!: boolean;

  @Prop({ default: () => [] })
  public additionalActionButtons: any;

  @Prop({ default: () => [] })
  public additionalToolbarButtons: any;

  @Prop({ default: () => [] })
  public editableActionButtons: any;

  @Prop({ default: false })
  public rowDraggable!: boolean;

  @Prop({ default: "query" })
  public initialSortBy!: string | Object;

  // TODO analyse and refactor custom route props
  @Prop({ default: null })
  public customSearchUrl!: string | null;

  @Prop({ default: null })
  public customQueryRoute!: string | null;

  public name: string = "MisTable";
  private createPageItems: any = [];
  public deletingId: number = 0;
  protected approvingRow: any;
  protected deleteInfo: string = "";
  protected approveInfo: string = "";
  protected defaultPerPage = AppConfig.DEFAULT_TABLE_RESULTS_PER_PAGE;
  protected defaultPerPageOptions = AppConfig.DEFAULT_TABLE_PER_PAGE_OPTIONS;
  protected currentPerPage = AppConfig.DEFAULT_TABLE_RESULTS_PER_PAGE;
  protected currentPage = 1;
  protected searchTerms: Array<any> = [];
  protected columnFilters: any = {};
  protected sortParameters: Array<any> = [];

  @Watch("$route", { immediate: true, deep: true })
  protected onUrlChange() {
    this.setQueryParamsFromRoute();
    this.search(false);
  }

  @Watch("deleted", { immediate: true, deep: true })
  protected onDeleted(deleted: any) {
    if (deleted) {
      this.search(false);
    }
  }

  public get searchTerm(): string {
    return this.searchTerms.length > 0 ? this.searchTerms[0].value : "";
  }

  public get addButtonUrl(): string {
    if (this.addRouteName) {
      const props = this.$router.resolve({ name: this.addRouteName });
      return props.href;
    }

    return "/" + this.resourceName + "/create";
  }

  public search(updateQueryParams: boolean = true): void {
    this.searchByParams(
      this.columnFilters,
      this.sortParameters,
      this.currentPerPage,
      this.currentPage
    );
    if (updateQueryParams) {
      this.setQueryParams();
    }
  }

  public searchByParams(
    columnFilters: any,
    sortParameters: Array<any>,
    currentPerPage: number,
    currentPage: number
  ): void {
    const url: string = this.customSearchUrl
      ? this.customSearchUrl
      : this.resourceName;
    this.searchAction({
      resource: url,
      params: {
        currentPerPage: currentPerPage,
        currentPage: currentPage,
        columnFilters: columnFilters,
        sort: sortParameters
      }
    });
  }

  public setQueryParamsFromRoute() {
    if (this.$route.query.currentPage) {
      this.currentPage = Number.parseInt(String(this.$route.query.currentPage));
    } else {
      this.currentPage = 1;
    }
    if (this.$route.query.currentPerPage) {
      this.currentPerPage = Number.parseInt(
        String(this.$route.query.currentPerPage)
      );
    } else {
      this.currentPerPage = this.defaultPerPage;
    }
    if (this.$route.query.searchTerms) {
      this.searchTerms = JSON.parse(String(this.$route.query.searchTerms));
    } else {
      this.searchTerms = [];
    }
    if (this.$route.query.sortParameters) {
      this.sortParameters = JSON.parse(
        String(this.$route.query.sortParameters)
      );
    } else {
      this.sortParameters = [];
    }
    if (this.$route.query.columnFilters) {
      this.columnFilters = JSON.parse(String(this.$route.query.columnFilters));
    } else {
      this.columnFilters = {};
    }
  }

  public setQueryParams() {
    let query: any = {
      currentPerPage: this.currentPerPage,
      currentPage: this.currentPage
    };
    if (this.searchTerms && this.searchTerms.length > 0) {
      query.searchTerms = JSON.stringify(this.searchTerms);
    }
    if (this.sortParameters && this.sortParameters.length > 0) {
      query.sortParameters = JSON.stringify(this.sortParameters);
    }
    if (this.columnFilters && Object.entries(this.columnFilters).length) {
      query.columnFilters = JSON.stringify(this.columnFilters);
    }
    const queryRoute = this.customQueryRoute
      ? this.customQueryRoute
      : this.resourceName;
    router.push({ path: `/${queryRoute}`, query: query });
  }

  private getResourceNameById(id: number): string {
    const resource = this.dataList.filter((x: any) => x.id == id);

    if (resource.length > 0) {
      if (resource[0].hasOwnProperty("name")) {
        return resource[0].name;
      }

      if (resource[0].hasOwnProperty("size")) {
        return resource[0].size;
      }

      if (resource[0].hasOwnProperty("email")) {
        return resource[0].email;
      }
    }

    return "";
  }

  protected okDialogDelete() {
    this.$bvModal.hide("modal-delete-confirm");
    this.deleteAction({
      data: this.getResourceNameById(this.deletingId),
      resource: this.deleteResource || this.resourceName,
      id: this.deletingId,
      resourceName: this.resourceName.replace("-", "_")
    });
  }

  public deleteItem(id: number): void {
    this.deleteInfo = String(
      i18n.t("general.question_delete_item", {
        resourceName: this.getResourceNameById(id)
      })
    );

    this.deletingId = id;

    this.$bvModal.show("modal-delete-confirm");
  }

  public onSearch(params: any) {
    this.searchTerms = this.searchFields.map((fieldName: any) => {
      return { field: fieldName, value: params.searchTerm };
    });
    this.search();
  }

  public onPageChange(params: any) {
    this.currentPage = params.currentPage;
    this.currentPerPage = params.currentPerPage;
    this.search();
  }

  public onPerPageChange(params: any) {
    this.currentPage = 1;
    this.currentPerPage = params.currentPerPage;
    this.search();
  }

  public onSortChange(params: any) {
    this.currentPage = 1;
    this.sortParameters = params;
    this.search();
  }

  public onColumnFilter(params: any) {
    this.columnFilters = params.columnFilters;
    this.currentPage = 1;
    this.search();
  }

  public onTableRowDrop(e: Event, data: any) {
    this.$emit("on-table-row-drop", e, data);
  }

  public onPersistDataListOrder(e: Event) {
    this.$emit("on-persist-data-list-order", e);
  }

  public approveItem(row: any): void {
    this.approveInfo = String(
      i18n.t("general.question_approve_item", {
        resourceName: this.getResourceNameById(row.id),
        type: row.approved
          ? String(this.$t("general.reject")).toLowerCase()
          : String(this.$t("general.approve")).toLowerCase()
      })
    );

    this.approvingRow = row;

    this.$bvModal.show("modal-approve-confirm");
  }

  public resetFilters(): void {
    this.sortParameters = [];
    this.defaultPerPage = 1;

    this.currentPerPage = this.defaultPerPage;
    this.currentPage = 1;
    this.searchTerms = [];
    this.columnFilters = {};
    this.$router.push({});

    // trigger VgtPagination.perPage change
    this.defaultPerPage = AppConfig.DEFAULT_TABLE_RESULTS_PER_PAGE;
    this.currentPerPage = this.defaultPerPage;
    this.search();
  }

  public reloadData(): void {
    this.search(false);
  }

  public objectByString(o: any, s: any) {
    s = s.replace(/\[(\w+)\]/g, ".$1");
    s = s.replace(/^\./, "");
    const a = s.split(".");
    let i = 0,
      n = a.length;
    for (; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  public prettyPrint(
    item: Array<Object>,
    printField: any,
    separate: string = "",
    colon: "",
    afterColonField: any
  ) {
    let data: string = "";
    item.forEach((newItem: any) => {
      data +=
        this.objectByString(newItem, printField) +
        colon +
        newItem[afterColonField] +
        separate +
        " ";
    });
    const lastIndex = data.lastIndexOf(separate + " ");
    return data.substring(-1, lastIndex);
  }

  public downloadFn(props: any) {
    if (props.column.hasOwnProperty("downloadFn")) {
      props.column.downloadFn(props);
    } else {
      const { id } = props.row;
      this.download(
        `${props.column.image.downloadableField}${id}`,
        `${id}.pdf`
      );
    }
  }

  public mounted() {
    // It has nothing to do here
    // Remove the shit if no errors after time (after 2.10.24)
    /*
    this.createPageItems = [
      {
        index: 1,
        text: i18n.t("general.wine"),
        callback: () => {
          this.$emit("add-wine");
        }
      },
      {
        index: 1,
        text: i18n.t("general.advertising"),
        callback: () => {
          this.$emit("add-ad");
        }
      },
      {
        index: 1,
        text: i18n.t("general.content_page"),
        callback: () => {
          this.$emit("add-content");
        }
      },
      {
        index: 1,
        text: i18n.t("general.content_page"),
        callback: () => {
          this.$emit("add-content");
        }
      }
    ];
    
     */
  }

  protected isValidColumn(column: any): boolean {
    return (
      !column.rendererName &&
      !column.currency &&
      !column.image &&
      column.field !== "actions" &&
      column.field !== "editable_actions" &&
      column.field !== "logo_actions"
    );
  }
}
