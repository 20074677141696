export default [
  { name: "linth", value: "Linth" },
  { name: "Helvetia", value: "Helvetia" },
  { name: "Limmat", value: "Limmat" },
  { name: "Saturn", value: "Saturn" },
  { name: "Schwan", value: "Schwan" },
  { name: "Sntis", value: "Säntis" },
  { name: "Stadt Rapperswil", value: "Stadt Rapperswil" },
  { name: "Stadt Zürich", value: "Stadt Zürich" },
  { name: "Pfannenstiel", value: "Pfannenstiel" },
  { name: "MS Rosenstadt", value: "MS Rosenstadt" },
  { name: "MS Uetliberg", value: "MS Uetliberg" }
];
